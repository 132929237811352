import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "buttonText"]

  connect() {
    this.showDetails = sessionStorage.getItem(`showDetails-${this.element.dataset.monitorId}`) === 'true'
    this.toggleContent()
  }

  toggle() {
    this.showDetails = !this.showDetails
    sessionStorage.setItem(`showDetails-${this.element.dataset.monitorId}`, this.showDetails)
    this.toggleContent()
  }

  toggleContent() {
    if (this.showDetails) {
      this.contentTarget.classList.remove("hidden")
      this.buttonTextTarget.textContent = "Hide Details"
    } else {
      this.contentTarget.classList.add("hidden")
      this.buttonTextTarget.textContent = "Show Details"
    }
  }
}
