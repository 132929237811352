import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  connect() {
    if (!this.element._flatpickr) {
      this.picker = flatpickr(this.element, {
        altInput: true,
        altFormat: "d/m/Y", // Display as 30/10/2024
        dateFormat: "Y-m-d", // Submit as 2024-10-30 (backend-compatible)
      });
    }
  }

  disconnect() {
    if (this.picker) {
      this.picker.destroy();
    }
  }
}
